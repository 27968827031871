<template>
  <div>
    <!-- แถบแรก -->
    <b-card no-body>
      <b-card-header>
        <h4 class="card-title">
          ภาพรวมสมาชิก
        </h4>
      </b-card-header>
      <div class="card-content">
        <b-card-body>
          <!-- Table Function -->
          <b-row class="mb-2">
            <b-col cols="12 col-md-6">
              <div>
                <b-input-group>

                  <b-input-group-prepend>
                    <span class="input-group-text" label="date_start_report"> วันที่ (จาก) </span>
                  </b-input-group-prepend>
                  <flat-pickr v-model="date_start_report" id="date_start_report" class="form-control text-center" />

                  <b-input-group-prepend>
                    <span class="input-group-text" label="date_end_report"> วันที่ (ถึง) </span>
                  </b-input-group-prepend>
                  <flat-pickr v-model="date_end_report" id="date_end_report" class="form-control text-center" />

                </b-input-group>
              </div>
            </b-col>
            <b-col cols="12 col-md-6">
              <div class="btn-group">
                <b-button @click="fetch()" variant="btn-primary" class="btn bg-gradient-primary waves-effect waves-light"> ค้นหา </b-button>
              </div>
            </b-col>
          </b-row>
          <!-- End Table Function -->
        </b-card-body>
      </div>
    </b-card>
    <!-- สิ้นสุดแถบแรก -->
    <!-- แถบแรก -->
    <b-card no-body>
      <b-card-header>
        <h4 class="card-title">
          รายการสมาชิกใหม่
        </h4>
      </b-card-header>
      <div class="card-content">
        <b-card-body>
          <!-- Table Function -->
          <b-row class="mb-2">
            <b-col cols="12 col-md-4">
              <h5> สมาชิกใหม่ทั้งหมด <span class="badge badge-primary">0</span> คน </h5>
            </b-col>
            <b-col cols="12 col-md-4">
              <h5> สมาชิกใหม่ที่รับเครดิตฟรี <span class="badge badge-primary">0</span> คน </h5>
              <h5> รวมโบนัส <span class="badge badge-primary">0</span> เครดิต </h5>
              <b-button class="btn btn-secondary btn-sm"> รายละเอียด </b-button>
            </b-col>
            <b-col cols="12 col-md-4">
              <h5> สมาชิกใหม่ที่รับเครดิตฟรีและมีการฝาก <span class="badge badge-primary">0</span> คน </h5>
              <h5> รวมยอดฝาก <span class="badge badge-primary">0</span> บาท </h5>
              <b-button class="btn btn-secondary btn-sm"> รายละเอียด </b-button>
            </b-col>
          </b-row>
          <!-- End Table Function -->
        </b-card-body>
      </div>
    </b-card>
    <!-- สิ้นสุดแถบแรก -->
    <!-- แถบแรก -->
    <b-card no-body>
      <b-card-header>
        <h4 class="card-title">
          รายการฝาก
        </h4>
      </b-card-header>
      <div class="card-content">
        <b-card-body>
          <!-- Table Function -->
          <b-row class="mb-2">
            <b-col cols="12 col-md-4">
              <h5> จำนวนสมาชิกที่มีการฝากทั้งหมดวันนี้ <span class="badge badge-primary">0</span> คน </h5>
              <h5> รวมยอดฝาก <span class="badge badge-primary">0</span> บาท </h5>
              <b-button class="btn btn-secondary btn-sm"> รายละเอียด </b-button>
            </b-col>
            <b-col cols="12 col-md-4">
              <h5> จำนวนสมาชิกใหม่ ที่มีการฝากครั้งแรก <span class="badge badge-primary">0</span> คน </h5>
              <h5> รวมยอดฝาก <span class="badge badge-primary">0</span> บาท </h5>
              <b-button class="btn btn-secondary btn-sm"> รายละเอียด </b-button>
            </b-col>
            <b-col cols="12 col-md-4">
              <h5> จำนวนสมาชิกเก่า ที่มีการฝาก <span class="badge badge-primary">0</span> คน </h5>
              <h5> รวมยอดฝาก <span class="badge badge-primary">0</span> บาท </h5>
              <b-button class="btn btn-secondary btn-sm"> รายละเอียด </b-button>
            </b-col>
          </b-row>
          <!-- End Table Function -->
        </b-card-body>
      </div>
    </b-card>
    <!-- สิ้นสุดแถบแรก -->
    <!-- แถบแรก -->
    <b-card title="จำนวนสมาชิก">
      <div class="d-flex justify-content-between flex-wrap">
        <div class="mb-1 mb-sm-0">
          <span class="text-muted">กราฟแสดงจำนวนสมาชิก</span>
        </div>
      </div>

      <!-- echart -->
      <app-echart-stacked-area style="width: 100% !important;" :option-data="option_member" />
    </b-card>
    <!-- สิ้นสุดแถบแรก -->
    <!-- แถบแรก -->
    <b-card title="ยอดฝาก">
      <div class="d-flex justify-content-between flex-wrap">
        <div class="mb-1 mb-sm-0">
          <span class="text-muted">กราฟแสดงยอดฝาก</span>
        </div>
      </div>

      <!-- echart -->
      <app-echart-stacked-area style="width: 100% !important;" :option-data="option_deposit" />
    </b-card>
    <!-- สิ้นสุดแถบแรก -->
  </div>
</template>

<script>
import {
  BCard, BCardHeader, BCardBody, BCol, BRow, BTable, BInputGroup, BInputGroupPrepend, BFormInput, BButton, BFormGroup, BInputGroupAppend, BFormSelect,
  BPagination, BDropdown, BDropdownItem, BDropdownDivider
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import flatPickr from 'vue-flatpickr-component'
import func from '@core/comp-functions/misc/func'
import AppEchartStackedArea from '@core/components/charts/echart/AppEchartStackedArea.vue'

export default {
  components: {
    BCard, BCardHeader, BCardBody,
    BCol, BRow, BTable,
    BInputGroup, BInputGroupPrepend, BFormInput, BFormGroup, BInputGroupAppend,
    BButton,
    BFormSelect,
    BPagination,
    BDropdown, BDropdownItem, BDropdownDivider,
    vSelect,
    flatPickr,
    AppEchartStackedArea,
  },
  data() {
    return {
      func: func,
      BASE_URL: process.env.BASE_URL,
      date_start_report: '',
      date_end_report: '',
      selected: { title: 'ทั้งหมด', value: '' },
      option: [
        { title: 'ทั้งหมด', value: '' },
      ],
      draw: 1,
      perPage: 25,
      pageOptions: [10, 25, 50, 100],
      totalRows: 50,
      currentPage: 5,
      recordsTotal: 0,
      filter: '',
      filterOn: [],
      fields: [
        { key: 'prefix', label: 'PREFIX', class: 'text-center' },
        { key: 'from_account_username', label: 'ผู้แนะนำ', class: 'text-center' },
        { key: 'to_account_username', label: 'ผู้สมัคร', class: 'text-center' },
        { key: 'created_at', label: 'วันที่สมัคร', class: 'text-center' },
      ],
      items: [],
      option_member: {
        xAxisData: ['สมาชิกใหม่', 'สมาชิกใหม่ที่รับเครดิตฟรี', 'สมาชิกที่มีการฝากทั้งหมด', 'รับเครดิตฟรีและมีการฝาก', 'สมาชิกใหม่ฝากครั้งแรก', 'สมาชิกเก่า ที่มีการฝาก'],
        series: [
          {
            name: 'จำนวนสมาชิก',
            type: 'line',
            stack: 'Total',
            areaStyle: {},
            showSymbol: true,
            lineStyle: {
              width: 2,
            },
            data: [0, 0, 0, 0, 0, 0],
          },
        ],
      },
      option_deposit: {
        xAxisData: ['ยอดฝากทั้งหมด', 'สมาชิกใหม่ฝากครั้งแรก', 'รับเครดิตฟรีและฝากครั้งแรก', 'สมาชิกเก่า', 'โบนัสเครดิตฟรี'],
        series: [
          {
            name: 'ยอดฝาก',
            type: 'line',
            stack: 'Total',
            areaStyle: {},
            showSymbol: true,
            lineStyle: {
              width: 2,
            },
            data: [0, 0, 0, 0, 0],
          },
        ],
      },
      t_fetch: 0,
      bank_list: JSON.parse('{"01":"\u0e01\u0e23\u0e38\u0e07\u0e40\u0e17\u0e1e","02":"\u0e01\u0e2a\u0e34\u0e01\u0e23\u0e44\u0e17\u0e22","03":"\u0e01\u0e23\u0e38\u0e07\u0e44\u0e17\u0e22","04":"\u0e17\u0e2b\u0e32\u0e23\u0e44\u0e17\u0e22","05":"\u0e44\u0e17\u0e22\u0e1e\u0e32\u0e13\u0e34\u0e0a\u0e22\u0e4c","06":"\u0e01\u0e23\u0e38\u0e07\u0e28\u0e23\u0e35\u0e2d\u0e22\u0e38\u0e18\u0e22\u0e32","07":"\u0e2d\u0e2d\u0e21\u0e2a\u0e34\u0e19","08":"\u0e18\u0e19\u0e0a\u0e32\u0e15\u0e34","09":"\u0e18.\u0e01.\u0e2a.","1":"\u0e01\u0e23\u0e38\u0e07\u0e40\u0e17\u0e1e","2":"\u0e01\u0e2a\u0e34\u0e01\u0e23\u0e44\u0e17\u0e22","3":"\u0e01\u0e23\u0e38\u0e07\u0e44\u0e17\u0e22","4":"\u0e17\u0e2b\u0e32\u0e23\u0e44\u0e17\u0e22","5":"\u0e44\u0e17\u0e22\u0e1e\u0e32\u0e13\u0e34\u0e0a\u0e22\u0e4c","6":"\u0e01\u0e23\u0e38\u0e07\u0e28\u0e23\u0e35\u0e2d\u0e22\u0e38\u0e18\u0e22\u0e32","7":"\u0e2d\u0e2d\u0e21\u0e2a\u0e34\u0e19","8":"\u0e18\u0e19\u0e0a\u0e32\u0e15\u0e34","9":"\u0e18.\u0e01.\u0e2a."}'),
    }
  },
  mounted() {
    this.totalRows = this.items.length
    this.fetch()
    this.fetch_prefix()
    this.t_fetch = setInterval(() => {
      this.fetch()
    }, 1000 * 60)
  },
  watch: {
    filter: {
      handler() {
        this.fetch()
        this.totalRows = this.items.length
        this.currentPage = 1
      },
    }
  },
  methods: {
    onFiltered(filteredItems) {
      // this.totalRows = filteredItems.length
      // this.currentPage = 1
    },
    onPageSelect() {
      let start = this.perPage * (this.currentPage - 1)
      this.fetch(start)
    },
    onPageChange(page) {
      let start = this.perPage * (page - 1)
      this.fetch(start)
    },
    load(data) {
      if (data == null || data.data == null) return;
      this.draw = data.draw
      this.items = data.data
      this.totalRows = data.recordsFiltered
      this.recordsTotal = data.recordsTotal
    },
    fetch(start=0) {
      let url = `ref/ref_list_page?draw=${this.draw}&start=${start}&length=${this.perPage}&search%5Bvalue%5D=${this.filter}&prefix=${this.selected.value}&date_start=${this.date_start_report}&date_end=${this.date_end_report}&status=&_=${Date.now()}`;
      this.$http.get(url).then(res => { this.load(res.data) })
      
    },
    fetch_prefix() {
      this.$http.get('ref/prefix').then(res => { res.data.forEach(data=> this.option.push({title: data.prefix, value: data.prefix}))})
    },
  },
  beforeRouteLeave (to, from, next) {
    clearInterval(this.t_fetch)
    next()
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
.vs__dropdown-toggle, .vs__dropdown-toggle:hover { border-top-left-radius: 0; border-bottom-left-radius: 0; }
</style>
